<template>
  <div class="container">
    <h1>My account</h1>

    <div class="row">
      <div class="col-md mb-4">
        <AccountForm v-if="item" v-bind:item="item" />
      </div>

      <div class="col-md mb-4">
        <PasswordForm v-if="accountId" v-bind:accountId="accountId" />
      </div>
    </div>    
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useStore } from '@/store';
import { ActionTypes } from '@/store/action-types';
import AccountForm from '@/components/AccountForm.vue';
import { AccountModel } from '@/models/account-model';
import PasswordForm from '@/components/PasswordForm.vue';
import { setFocus } from '@/services/dom-tools';

export default defineComponent({
  name: 'MyAccount',
  components: {
    AccountForm,
    PasswordForm,
  },

  setup() {
    const store = useStore();
    return { store };
  },

  mounted() {
    this.store.dispatch(ActionTypes.LOAD_ALL_DATA).then(
      () => this.onDataLoaded(),
      () => this.$router.push({ name: "Signin" })
    );
  },

  data: () => ({
    item: undefined as AccountModel | undefined,
    accountId: undefined as string | undefined,
  }),

  computed: {
    loading(): boolean { return this.store.state.loadingData; },
  },

  methods: {
    getAccountById(id: string): AccountModel {
      return this.store.getters.getAccountById(id);
    },
    
    onDataLoaded(): void {
      if (this.store.state.jwt.data) {
        this.accountId = this.store.state.jwt.data?.id;
        this.item = this.getAccountById(this.accountId);
        setFocus('firstName');
      }
    },
  },
});
</script>
